.footer-wrapper{
    z-index: 0;
    bottom: 0;
    width: 100%;
    
}
.site-footer {
    padding: 30px 0 20px;
    background: #161b1f;
    color: #6c7980;
    font-size: 14px;
}

.site-footer a {
    color: #ccc;
}

.site-footer ul {
    margin: 0 0 0 1em;
    padding: 0;
}

.social-bar {
    /* background: #fff; */
    border-top: 1px solid #06A4AB;
    /* border-bottom: 1px solid #06A4AB; */
    padding: 5px 0 50px;
    /* margin-top: 2px; */
    text-align: center;
    font-size: 20px;
    /* color: #888; */
}

.social-bar span {
    float: left;
    font-size: 22px;
    margin-right: 2%;
    text-align: right;
    width: 48%;
}

.social-bar .fa {
    /* color: #888888; */
    font-size: 22px !important;
    padding: 10px 7px 0;
}

.social-title {
    font-weight: 200;
}

.social-media-icons {
    float: left;
    margin: 0;
    text-align: center;
    padding-left: 0;
    width: 100%;
}

.social-media-icons li {
    color: #888888;
    display: inline-block;
    float: none;
    list-style: none outside none;
    line-height: 0px !important;
}

.social-media-icons .fa a {
    font-size: 18px !important;
    margin: 0 20px;
    padding: 0 7px;
    line-height: 0px !important;
    display: inline-block !important;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    /* ...and now for the proper property */
    transition: 0.5s;
}

.social-media-icons .fa:hover {
    color: #039BE5;
}

.social-media-icons .fa:hover a {
    color: #039BE5;
}

.social {
    margin: 1rem 0;
}

.footer-icon {
    color: #F9F9F9;
    padding: 0px 8px;
    transition: all 300ms;
}

.footer-icon:hover{
    color: #039be5;
}

.site-footer {
	padding: 50px 0 20px;
	background: #004166;
	color: #9e9e9e;
    font-size: 14px;  
    
}

.site-footer a {
	color: #ccc;
}

.site-footer ul {
	margin: 0 0 0 1em;
	padding: 0;
}
.site-info{
    width: 90%;
    margin: auto;
    text-align: left;
   
}
